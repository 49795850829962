@import '../styles/variables';
@import './components/UserMenu/styles.scss';
@import './components/Logo/styles.scss';
@import './components/Lab/styles.scss';
@import './Footer/styles.scss';
@import './Header/styles.scss';

html {
  background-color: $white;
}

body {
  margin: 0;
  padding: 0;
  background-color: $gray-040-solid;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: auto;
}

main {
  flex-grow: 1;
  display: block;
  width: 100%;
}
