.nav-logo {
  background-repeat: no-repeat;
  background-size: 134px 34px;
  height: 34px;
  width: 134px;
  top: 11px;
  position: absolute;

  &__mobile {
    @extend .nav-logo;
    width: 44px;
    height: 32px;
    background-size: 44px 32px !important;
  }
}