.nav-header__logo-wrapper {
  position: relative;
}

.nav-header__labs {
  display: flex;
  align-items: center;
  height: 20px;
  position: absolute;
  top: 29px;
  left: 92px;
  padding: 0 2px 0 4px;
  margin: 0;
  font-size: 11px;
  text-decoration: none;
  color: $white;
  fill: $white;
  border-radius: 2px;
  background-color: $lab-blue;
}

.nav-header__svg {
  position: static;
  height: 16px;
  transform: rotate(32deg);
  fill: inherit;
  opacity: 1;
}
