$white: #fff !default;
$black: #000 !default;
$gray: #333 !default;
$purple: #AB86FF !default;
$brand-yellow: #FFE600 !default;

$gray-100: rgba($black, 0.1) !default;
$gray-080: rgba($black, 0.08) !default;
$gray-300: rgba($black, 0.3) !default;
$gray-040-solid: #F5F5F5 !default;
$dropdown-select-gray: #f9f9f9 !default;
$border-gray: #e6e6e6 !default;
$light-gray: #eee !default;
$dark-gray: #999 !default;
$lab-blue: #3483fa !default;

$max-layout-width: 1200px;
$header-height: 56px;
$footer-height: 70px;
