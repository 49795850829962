.nav-footer {
  border-color: $border-gray;
  background-color: $white;
  border-top: 1px solid $light-gray;
  width: 100%;
  display: block;
  position: relative;
  overflow: unset;
  height: $footer-height;

  .nav-footer-info-wrapper {
    max-width: $max-layout-width;
    margin: 0 auto;

    @media (max-width: $max-layout-width) {
      padding: 0 8px;
    }
  }

  .nav-footer-secondary-info {
    font-size: 12px;
    width: auto;
    color: $dark-gray;
    line-height: initial;
    display: block;
    margin: 10px 0px;
  }
}
