.user-menu {
  text-align: right;
  height: $header-height;
  min-width: 340px;

  .user-item {
    padding: 0 10px;
    line-height: 55px;
    font-size: 14px;
    color: $gray;
    text-decoration: none;
    height: auto;

    &:hover {
      color: $black;
    }
  }
}

.user-dropdown {
  position: relative;
  display: inline-block;
  cursor: pointer;

  &:hover {
    .dropdown-content {
      display: block;
    }

    .nav-icon-user-rounded {
      opacity: 1;
    }
  }



  .dropdown-wrapper {
    height: $header-height;
    display: flex;
    align-items: center;
  }

  .nav-icon-user-rounded {
    margin-right: 8px;
    background-size: 20px;
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    position: absolute;
    opacity: 0.8;
    left: -15px;
  }

  .nav-header-username-chevron {
    border-style: solid;
    border-width: 0 1.5px 1.5px 0;
    content: '';
    height: 4px;
    position: relative;
    margin-left: 6px;
    transform: rotate(45deg);
    width: 4px;
    color: $gray-300;
    right: 0;
  }
}

.dropdown-content {
  display: none;
  border: none;
  position: absolute;
  border-radius: 2px;
  background-color: $dropdown-select-gray;
  min-width: 140px;
  box-shadow: 0 15px 20px 0 $gray-080, 0 1px 4px 0 $gray-080;
  right: 0;
  padding: 8px 0;
  z-index: 1014;

  .dropdown-item {
    font-size: 14px;
    padding: 8px 24px;
    line-height: 1.25;
    text-align: left;
    color: $gray;
    display: block;
    text-decoration: none;

    &:hover {
      background-color: $gray-040-solid;
    }
    &-separator {
      border-top: 1px solid #eeeeee;
      margin: 8px 0;
      display: block;
  }
  }
}
